import React, { Fragment, useEffect, useState } from 'react'
import { node, array, string, object } from 'prop-types'
import { MultiSelect } from '@livechat/design-system'
import Layout from '../../layout'
import { SEO, Image, GetStarted } from '../../components'
import AppIdeasOgImage from '../../assets/images/app-ideas-seo-image.png'
import ChevronUp from '../../assets/icons/chevron-up.svg'
import ChevronDown from '../../assets/icons/chevron-down.svg'
import CheckIcon from '../../assets/icons/check.svg'
import CloseIcon from '../../assets/icons/close.svg'

import { lcIdeas, hdIdeas } from '../../config'
import './app-ideas.less'

const Panel = ({ title, description, features }) => {
  return (
    <section className="u-bg-black2 u-rounded u-p-xl u-flex u-flex-col u-Mb-xs u-w-full">
      <h3 className="u-Mb-xs">{title}</h3>

      <div className="u-Mb-xs u-text-gray-700 feature-width">{description}</div>

      <div className="u-flex u-justify-between u-w-full u-flex-col lg:u-flex-row">
        <div className="u-flex u-flex-wrap features-wrapper">
          {features.map((feature, index) => (
            <div
              key={`feature-${index}`}
              className="feature feature-color u-text-p9-bold u-rounded-xl u-py-xs u-px-md u-text-gray-600 u-border-gray-400 u-border u-flex u-items-center u-justify-center"
            >
              {feature}
            </div>
          ))}
        </div>
        <Fragment>
          <div className="u-flex u-items-center u-justify-center u-text-p6-bold">
            <a
              href={`mailto:developers@livechat.com?Subject=App Ideas: ${title}`}
              target="_blank"
              rel="noopener noreferrer"
              className="contact-button"
            >
              Contact us
            </a>
          </div>
          <div className="u-flex u-Mt-2xs start-building">
            <a
              href={`${
                process.env.GATSBY_CONSOLE_URL
              }apps/?utm_source=app_ideas`}
              target="_blank"
              rel="noopener noreferrer"
              className="u-w-full lg:u-w-fit "
            >
              <button className="c-btn v--tertiary u-w-full lg:u-w-fit">
                Start building
              </button>
            </a>
          </div>
        </Fragment>
      </div>
    </section>
  )
}

Panel.propTypes = {
  title: node,
  description: node,
  features: array,
}

const AppIdeas = ({ location }) => {
  const initialProductFilter = () => {
    if (location.search.includes('hd')) {
      return ['HelpDesk']
    } else {
      return ['LiveChat', 'HelpDesk']
    }
  }
  const [ideas, setIdeas] = useState([])
  const [selectedItems, setSelectedItems] = useState(initialProductFilter)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const items = [
    { key: 'HelpDesk', props: { name: 'HelpDesk', value: 'HelpDesk' } },
    { key: 'LiveChat', props: { name: 'LiveChat', value: 'LiveChat' } },
  ]

  useEffect(
    () => {
      if (
        selectedItems.includes('LiveChat') &&
        selectedItems.includes('HelpDesk')
      ) {
        setIdeas([...hdIdeas, ...lcIdeas])
      } else if (selectedItems.includes('LiveChat')) {
        setIdeas([...lcIdeas])
      } else if (selectedItems.includes('HelpDesk')) {
        setIdeas([...hdIdeas])
      } else {
        setIdeas([])
      }
    },
    [selectedItems]
  )

  const handleItemSelect = item => {
    if (!selectedItems) {
      return setSelectedItems([item])
    }
    if (selectedItems?.some(selected => item === selected)) {
      const newState = selectedItems.filter(selected => item !== selected)
      return setSelectedItems(newState)
    }
    return setSelectedItems([...selectedItems, item])
  }

  const handleItemRemove = item => {
    const newState = selectedItems.filter(selected => selected !== item)
    return setSelectedItems(newState.length > 0 ? newState : null)
  }

  const getItemBody = props => {
    if (!props) {
      return null
    }

    return (
      <div className="selectOption" id={props.value}>
        <span
          className={`checkbox ${
            selectedItems.includes(props.value) ? 'checkbox-selected' : ''
          }`}
        >
          {selectedItems.includes(props.value) ? (
            <CheckIcon className="checkbox-icon" width="12" />
          ) : (
            ''
          )}
        </span>
        <span>{props.name}</span>
      </div>
    )
  }

  getItemBody.propTypes = {
    value: string,
    name: string,
  }

  const handleDropdownToggle = isOpen => {
    setIsDropdownOpen(isOpen)
  }

  const getSelectedItemBody = () => {
    return (
      <div>
        Products ({selectedItems.length}){' '}
        <span className="chevron-icon">
          {isDropdownOpen ? <ChevronUp /> : <ChevronDown />}
        </span>
      </div>
    )
  }

  return (
    <Layout>
      <SEO
        title="LiveChat Platform – App Ideas"
        description="Browse real app ideas submitted by the LiveChat customers and get inspired to code your own solutions."
        image={AppIdeasOgImage}
      />
      <div className="u-bg-black-2 u-Pt-md u-Pb-xs">
        <GetStarted
          title={'APP IDEAS'}
          subtitle="You have the tools, we got the ideas"
          description="We know that finding a perfect idea for your app can be tricky. Below you can find real app ideas from the LiveChat customers."
          customImage={
            <div className="u-relative u-overflow-x">
              <Image type="appIdeasHero" />
            </div>
          }
          customButton={
            <div className="u-Mt-md">
              <a
                href={'#app-ideas-header'}
                className="u-Mt-xl  v--livechat u-text-white u-text-p6-bold explore-ideas"
              >
                Explore ideas
              </a>
            </div>
          }
        />
      </div>
      <div className="u-bg-black-2">
        <section className="o-container u-flex u-flex-col u-items-center u-justify-center">
          <div className="u-flex u-justify-between u-Mb-xs u-items-center u-w-full list-header">
            <h2
              id="app-ideas-header"
              className="u-text-p3 app-ideas-header-list"
            >
              App Ideas
            </h2>
            <div className="filter-controls">
              {selectedItems.length > 0 && (
                <button
                  className="filter-reset-button"
                  onClick={() => setSelectedItems([])}
                >
                  <span className="close-icon">
                    <CloseIcon />
                  </span>
                  Clear all filters
                </button>
              )}
              <MultiSelect
                items={items}
                onItemSelect={handleItemSelect}
                onItemRemove={handleItemRemove}
                getItemBody={getItemBody}
                getSelectedItemBody={getSelectedItemBody}
                onDropdownToggle={handleDropdownToggle}
                selected={selectedItems}
                placeholder={
                  <Fragment>
                    Products (0){' '}
                    <span className="chevron-icon">
                      {isDropdownOpen ? <ChevronUp /> : <ChevronDown />}
                    </span>
                  </Fragment>
                }
                maxItemsContainerHeight={64}
                isOpen={isDropdownOpen}
              />
            </div>
          </div>

          <div className="u-flex u-flex-col u-justify-between u-items-center  u-w-full">
            {ideas.map((idea, index) => (
              <Panel
                key={`idea-${index}`}
                title={idea.title}
                description={idea.description}
                features={idea.features}
              />
            ))}
          </div>
        </section>

        <div className="u-bg-black2 u-Pt-md lg:u-Pt-xl u-Pb-xs u-Mt-sm lg:u-Mt-lg explore-marketplace">
          <section className="o-container u-Mb-sm lg:u-Mb-lg o-columns v--two v--responsive ">
            <div>
              <h3 className="u-text-p3">Explore our Marketplace</h3>
              <p className="u-text-p6 u-mb-lg u-text-gray-700">
                Get inspired by apps that our customers love the most. Visit the
                LiveChat Marketplace and explore apps already built by other
                developers.
              </p>

              <a
                href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}marketplace/`}
                target="_blank"
                rel="noopener noreferrer"
                className="c-btn v--livechat u-text-white u-text-p6-bold marketplace-button"
              >
                Go to Marketplace
              </a>
            </div>

            <div className="marketplace-image-wrapper">
              <Image
                type="exploreMarketplaceRight"
                wrapperStyles={{
                  width: '100%',
                  maxHeight: '40vw',
                }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

AppIdeas.propTypes = {
  location: object,
}

export default AppIdeas
